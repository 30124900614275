<template>
  <v-flex>
    <template v-if="loading">
      <v-progress-linear indeterminate></v-progress-linear>
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-text-field
              autofocus
              outlined
              dense
              v-model="productCode"
              required
              ref="productInput"
              :label="$t('checklistReport.productCode')"
              @keyup.enter="fetchSavedChecklist(productCode)"
              @keyup.tab="fetchSavedChecklist(productCode)"
            ></v-text-field>
          </v-col>
          <v-col cols="1">
            <v-btn
              class="text-none"
              color="primary"
              @click="fetchSavedChecklist(productCode)"
              :disabled="!productCode"
            >
              {{$t('checklistReport.search')}}
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn
              class="text-none"
              color="error"
              @click="reset"
              :disabled="!productCode"
            >
              {{$t('checklistReport.reset')}}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-btn
              class="text-none"
              color="success"
              @click="generateReport"
              :disabled="checklistData === null"
            >
              <v-icon left>mdi-file-pdf</v-icon>
              {{$t('checklistReport.download')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="productCode && checklistData !== null">
      <vue-html2pdf
      :show-layout="true"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :filename="fileName()"
      :paginate-elements-by-height="2000"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :manual-pagination="false"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
      >
        <section class="mt-2" slot="pdf-content">
            <v-row>
              <v-card width="100%">
                <v-card-text>
                  <v-row>
                    <v-col cols="2">
                      <img
                        :src="`/assets/logo/shopworx-light.png`"
                        contain
                        height="52"
                      />
                    </v-col>
                    <v-col cols="2" >
                      <div style="margin-top: 20px;">
                        <b> {{ $t('checklistReport.productCode') }} </b>: {{ productCode }}
                      </div>
                    </v-col>
                    <v-col cols="3" >
                        <div style="margin-top: 20px;">
                          <b> {{ $t('checklistReport.part') }} </b>: {{ partDetails.partname }}
                        </div>
                      </v-col>
                    <v-col cols="3" >
                      <div style="margin-top: 20px;">
                        <b> {{ $t('checklistReport.model') }} </b>: {{ partDetails.model }}
                      </div>
                    </v-col>
                    <v-col cols="2" >
                      <div style="margin-top: 20px;">
                        <b> {{ $t('checklistReport.customer') }} </b>: {{ partDetails.customer }}
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
            <v-row v-for="cl in checklistData" :key="cl.machinename">
              <v-card class="mt-4 ml-3 stageClass" width="98%">
                <v-card-text>
                  <v-row style="color: #fff">
                    <v-col cols="4">
                      <b> {{ $t('checklistReport.stage') }} </b>: {{ cl.machinename }}
                    </v-col>
                    <v-col cols="4">
                      <b> {{ $t('checklistReport.productcreatedat') }}
                      </b> : {{ cl.productcreatedat }}
                    </v-col>
                    <v-col cols="4">
                      <b> {{ $t('checklistReport.lastUpdatedBy') }} </b> : {{ cl.operatorname }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mt-1 ml-3 pb-4" width="98%">
                <v-card-text>
                  <v-row v-for="(value, key) in cl.data" :key="`${cl.machinename}-${key}`">
                    <v-row style="margin-top: 30px; margin-left: 10px;"
                    class="checklistHeader pa-2">{{ key }}</v-row>
                    <div style="width:100%;">
                      <v-row v-for="item in value" :key="`${cl.machinename}-${item.parameter}`"
                      style="margin-left: 10px; margin-top: 15px;">
                        <v-row>
                          <v-col cols="6">
                            {{ item.parameter }}
                          </v-col>
                          <v-col>
                            {{ item.standards }}
                          </v-col>
                          <v-col>
                            {{ item.acceptancecriteria }}
                          </v-col>
                          <v-col>
                            {{ item.value }}
                          </v-col>
                        </v-row>
                      </v-row>
                    </div>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-row>
        </section>
      </vue-html2pdf>
    </v-card>
    <v-card v-if="productCode && !checklistData">
      <no-checklist/>
    </v-card>
  </v-flex>
</template>

<script>
import {
  mapState,
  mapActions,
  mapMutations,
  mapGetters,
} from 'vuex';
import VueHtml2pdf from 'vue-html2pdf';
import NoChecklist from './NoChecklist.vue';
// import { formatDate } from '@shopworx/services/util/date.service';

export default {
  name: 'Checklist',
  components: {
    VueHtml2pdf,
    NoChecklist,
  },
  data() {
    return {
      productCode: null,
    };
  },
  computed: {
    ...mapState('checklistReport', ['loading', 'productDetails']),
    ...mapGetters('checklistReport', ['checklistData', 'partDetails']),
    notFoundIllustration() {
      return this.$vuetify.theme.dark
        ? 'not-found-dark'
        : 'not-found-light';
    },
  },
  methods: {
    ...mapActions('checklistReport', ['fetchSavedChecklist']),
    ...mapMutations('checklistReport', [
      'setSavedChecklist',
    ]),
    fileName() {
      return `${this.productCode}-Checklist-Report`;
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    async beforeDownload({ html2pdf, pdfContent }) {
      await html2pdf().set({
        margin: [10, 0, 10, 0],
        filename: this.fileName(),
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' },
        pageBreak: { mode: 'css', after: '.break-page' },
      }).from(pdfContent).toPdf()
        .get('pdf')
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            // pdf.text(`Page ${i} of ${totalPages}`);
          }
        })
        .save();
    },
    reset() {
      this.productCode = null;
      this.setSavedChecklist([]);
    },
  },
};
</script>

<style>
.checklistHeader{
  background: #53af50;
  color: #fff;
  max-width: 98%;
  font-weight: bold;
}

.inputClass{
  height: 45px;
  margin: 0px;
}
.vue-html2pdf .layout-container {
  position: unset !important;
  background: #fff !important;
  width: 78vw !important;
}

.stageClass{
  background: #354493 !important;
  color: #fff;
}
</style>
